<template>
  <div class="attend">
    <div class="_title">报名缴费</div>
    <!--    <div class="btns">-->
    <!--      <div class="btn"  @click="jump('/register')">-->
    <!--       注册-->
    <!--      </div>-->
    <!--      <div class="btn" @click="jump('/login')">-->
    <!--       登录-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="desc">
      <div class="desc-title">
        一、会议采取在线报名，报名缴费通道：
      </div>
      <p>
        <a href="https://jinshuju.net/f/QWm3fy">报名缴费</a>（会议报名缴费）
      </p>
      <p>
        报名开放日期为2023年10月20日至2023年12月10日。在线报名限额1000人，额满即止。
      </p>
      <br />
      <div class="desc-title">
        二、会议报名费标准：
      </div>
      <p>
        2023年11月20日（含）前缴费：<br />在职代表：2000元/人，在校学生：1500元/人；
      </p>
      <p>
        2023年11月20日后缴费：<br />在职代表：2500元/人，在校学生：2000元/人；
      </p>
      <p>在校学生代表包含相关领域本科生、硕士研究生及博士生研究生。</p>
      <br />
      <div class="desc-title">
        三、报名及缴费注意事项
      </div>

      <p>
        ● 缴费标准以实际缴费时间为准。
      </p>
      <p>
        ●
        已报名并缴费但未能参会者，注册费不予退回，可由他人代替参会，需提前与会务组联系。
      </p>
      <br />
      <div class="desc-title">
        四、其他缴费方式：可通过银行转账或现场缴费（POS机刷卡/微信支付）。
      </div>

      <span style="font-weight: 700">银行汇款</span><br />
      <p>账户名称：石家庄博瑞迪生物技术有限公司</p>
      <p>纳税人识别号：91130101MA08GDWT8E</p>
      <p>
        地址、电话：石家庄高新区太行南大街769号京石协作创新示范园内202号厂房B（A12）栋504&nbsp;0311-66695178
      </p>
      <p>
        开户行及账号：中信银行股份有限公司石家庄高新技术开发区支行8111801011500445096
      </p>
      <p>
        （请务必附言：缴费类型：会议费/培训费/会议费+培训费，参会代表姓名+联系电话+
        2023年分子植物育种大会；集体汇款的单位请列出每位参会代表姓名。）
        现场缴费者不保证会议资料和酒店预订。
      </p>
      <br />
      <div class="desc-title">
        五、发票开具
      </div>
      <p>
        请在报名缴费时，留存姓名、手机、邮箱等真实联系信息及单位开票信息。<br />
        2023年11月20日（含）前付款的老师，会务组将在大会正式开始前陆续发送至您报名时预留的邮箱（均为电子发票形式）；<br />2023年11月20日后及现场付款的老师，会务组将在会后1周内开具发票，并发送至您报名时预留的邮箱（均为电子发票形式）。
        <br />注：根据国家税务总局石家庄税务局通知文件要求，我司将于12月1日正式注销税盘，本次大会开具的增值税专用发票和增值税普通发票均采用电子发票形式。
      </p>
      <br />

      <div class="desc-title">
        六、论文摘要和青年科学家论坛
      </div>
      <p>
        除基础报告、大会主旨和专题报告外，大会开辟“青年科学家论坛”交流渠道。大会鼓励参会代表提交论文摘要。
      </p>
      <p>
        <span>● 青年科学家论坛：</span
        >组委会将从40岁以下青年科学家提供的论文摘要中筛选8名青年科学家论坛报告人。大会将根据报告结果评选优秀报告一等奖和二等奖。
      </p>
      <p>
        <span>● 青年科学家论坛摘要投稿：</span
        >请参会代表在会议网站提交上传。（模板及要求：请见“下载中心”）请用标准英文撰写摘要，文责自负。报告摘要提交截止时间：2023年11月30日。
      </p>
      <!--      <p>-->
      <!--        <span>● 墙报交流：</span>接受自由投稿和张贴。设立优秀墙报奖。大会鼓励墙报交流，墙报由会务组统一制作，请于2022年2月16日前通过大会网站上传。（墙报模板，请至大会网站“下载中心”下载。）-->
      <!--      </p>-->
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
  methods: {
    jump(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.attend {
  padding: 0 50px;
  padding-bottom: 150px;
  .btns {
    display: flex;
    justify-content: center;
    .btn {
      min-width: 100px;
      width: 150px;
      height: 35px;
      line-height: 35px;
      color: #fff;
      background: #2e63a6;
      text-align: center;
      margin: 40px;
      cursor: pointer;
      a {
        color: #fff;
        text-decoration: none;
      }
      &:nth-child(1) {
        margin-right: 30px;
      }
    }
  }
  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
  .desc-title {
    color: #337fe5;
    font-weight: bold;
    margin: 10px 0;
  }
  p {
    margin-bottom: 4px !important;
    span {
      font-weight: bold;
    }
  }
}
</style>
